<template>
  <v-container class="pa-0" fluid>
    <page-title
        :heading="$t('erp.lang_ingredientGroupsAdd')"
        :icon="icon"
        :subheading="$t('erp.lang_ingredientGroupsAdd')"
        show-previous-button
    ></page-title>
    <div class="app-main__inner">
     <IngerdientGroupsCreateComponent></IngerdientGroupsCreateComponent>
    </div>
  </v-container>
</template>

<script>
//components
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import IngerdientGroupsCreateComponent from "../../../../components/erp/baseData/ingredientGroups/IngerdientGroupsCreateComponent";

export default {
  name: "IngredientGroupsCreate",
  components: {
    IngerdientGroupsCreateComponent,
    PageTitle,

  },
  data: () => ({
    icon: "mdi mdi-shaker-outline icon-gradient bg-tempting-azure"
  })
};
</script>

<style>
</style>