<template>
  <div class="form-group">
    <v-text-field :dense="dense" outlined :label=fieldLabel ref="picker" autocomplete="off" v-model="search"
                  @blur="blur"
                  @focus="focus" :rules="rules">
      <template v-slot:prepend-inner >
        <v-chip  v-if="selected" @click:close="remove" small close>
          <font-awesome-icon :icon="['fal', selected]" size="1x"/>
          . {{ selected }}
        </v-chip>
      </template>
                </v-text-field>

    <transition name="icon-preview-fade">
      <div v-if="focusOn" class="preview-container">
        <div @click="select(undefined)" @mouseover="hoverPanel = true" @mouseout="hoverPanel = false"
             :class="['previewer', 'rounded', {'custom-shadow-sm': !hoverPanel}, {'custom-shadow': hoverPanel} ]">
          <div v-for="(i, index) in iconsFiltered" :key="index" class="icon-preview">
            <div @click.prevent.stop="select(i)"
                 :class="['icon-wrapper','rounded','shadow-sm', {selected: i.title === selected}]">
              <font-awesome-icon :icon="[i.type, i.title]" size="1x"/>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faHotdog,
  faCocktail,
  faMeat,
  faGifts,
  faHamburger,
  faCheeseburger,
  faFrenchFries,
  faPizzaSlice,
  faShishKebab,
  faFish,
  faCroissant,
  faSalad,
  faTaco,
  faSandwich,
  faSoup,
  faPersonCarry,
  faStopwatch,
  faBiking,
  faUserHeadset,
  faMapMarkerAlt,
  faHatChef,
  faKnifeKitchen,
  faWindowClose,
  faCheck,
  faFlagCheckered,
  faBinoculars,
  faProjector,
  faTablet,
  faIceCream,
  faGiftCard,
  faPizza,

  faBeer,

  faMugHot,

  faGlassCheers,

  faGlass,

  faUtensils,

  faFishCooked,

  faTurkey,

  faFlowerDaffodil,

  faFlowerTulip,

  faDumbbell,

  faMouse,

  faPlug,

  faWebcam,

  faUsbDrive,

  faWatchCalculator,

  faSdCard,

  faMobile,

  faGameConsoleHandheld,

  faGamepadAlt,

  faFlashlight,

  faBaseball,

  faToiletPaper,

  faShovel,


  faBackpack,

  faAxe,

  faHeadphones,

  faMp3Player,

  faDroneAlt,

  faCameraPolaroid,

  faAlbum,
  faKeyboard,
  faCompactDisc,

} from '@fortawesome/pro-light-svg-icons';

library.add(
    faHotdog,
    faCompactDisc,
    faCocktail,
    faMeat,
    faGifts,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faSalad,
    faTaco,
    faSandwich,
    faSoup,
    faPersonCarry,
    faStopwatch,
    faBiking,
    faUserHeadset,
    faMapMarkerAlt,
    faHatChef,
    faKnifeKitchen,
    faWindowClose,
    faCheck,
    faFlagCheckered,
    faProjector,
    faBinoculars,
    faPizza,
    faBeer,
    faMugHot,
    faGlassCheers,
    faGlass,
    faUtensils,
    faCroissant,
    faFishCooked,
    faTurkey,
    faIceCream,
    faFlowerDaffodil,
    faFlowerTulip,
    faDumbbell,
    faMouse,
    faPlug,
    faWebcam,
    faTablet,
    faUsbDrive,
    faWatchCalculator,
    faSdCard,
    faMobile,
    faIceCream,
    faGameConsoleHandheld,
    faGamepadAlt,
    faFlashlight,
    faBaseball,
    faShishKebab,
    faToiletPaper,
    faShovel,
    faFlashlight,
    faBinoculars,
    faBackpack,
    faAxe,
    faHeadphones,
    faMp3Player,
    faDroneAlt,
    faCameraPolaroid,
    faAlbum,
    faKeyboard,
    faTablet,
    faIceCream,
    faGiftCard,
);

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
  name: "FontawesomePicker",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },

  props: {
    value: String,
    fieldLabel: String,
    type: {
      type: String,
      default: 'food',
    },
    rules: {
      required: false,
    },
    dense: Boolean
  },

  data: function () {
    return {
      focusOn: false, // <- set this to false
      timeout: undefined,
      statusIcons: [
        {
          title: 'person-carry',
          searchTerms: [
            'personcary',
            'person',
            'cary'
          ],
          type: 'fal',
        },
        {
          title: 'stopwatch',
          searchTerms: [
            'stopwatch',
            'watch',
          ],
          type: 'fal',
        },
        {
          title: 'biking',
          searchTerms: [
            'biking',
            'bicycle',
            'motorcycle'
          ],
          type: 'fal',
        },
        {
          title: 'user-headset',
          searchTerms: [
            'user',
            'userheadset',
            'headset'
          ],
          type: 'fal',
        },
        {
          title: 'map-marker-alt',
          searchTerms: [
            'map',
            'marker',
            'mapmarker',
          ],
          type: 'fal',
        },
        {
          title: 'hat-chef',
          searchTerms: [
            'hat',
            'chef',
            'hatchef'
          ],
          type: 'fal',
        },
        {
          title: 'knife-kitchen',
          searchTerms: [
            'knife',
            'kitchen',
            'kinfekitchen'
          ],
          type: 'fal',
        },
        {
          title: 'window-close',
          searchTerms: [
            'window',
            'close',
            'windowclose'
          ],
          type: 'fal',
        },
        {
          title: 'check',
          searchTerms: [
            'check',
          ],
          type: 'fal',
        },
        {
          title: 'flag-checkered',
          searchTerms: [
            'flag',
            'start',
            'go'
          ],
          type: 'fal',
        },
        {
          title: 'pizza-slice',
          searchTerms: [
            'pizzaslice',
            'pizza',
            'food'
          ],
          type: 'fal',
        },
      ],
      foodIcons: [
        {
          title: 'hotdog',
          searchTerms: [
            'hotdog',
            'hot-dog',
          ],
          type: 'fal',
        },
        {
          title: 'projector',
          searchTerms: [
            'projector',
          ],
          type: 'fal',
        },
        {
          title: 'binoculars',
          searchTerms: [
            'binoculars',
          ],
          type: 'fal',
        },
        {
          title: 'gifts',
          searchTerms: [
            'gifts',
            'geschenke',
          ],
          type: 'fal',
        },
        {
          title: 'salad',
          searchTerms: [
            'salat',
            'salad',
          ],
          type: 'fal',
        },
        {
          title: 'cocktail',
          searchTerms: [
            'drinks',
            'cocktail',
          ],
          type: 'fal',
        },
        {
          title: 'hamburger',
          searchTerms: [
            'hamburger',
          ],
          type: 'fal',
        },
        {
          title: 'meat',
          searchTerms: [
            'fleisch',
            'meat',
          ],
          type: 'fal',
        },
        {
          title: 'cheeseburger',
          searchTerms: [
            'cheeseburger',
          ],
          type: 'fal',
        },
        {
          title: 'french-fries',
          searchTerms: [
            'fries',
            'pommes',
          ],
          type: 'fal',
        },
        {
          title: 'pizza-slice',
          searchTerms: [
            'pizza',
          ],
          type: 'fal',
        },
        {
          title: 'shish-kebab',
          searchTerms: [
            'kebab',
            'döner',
          ],
          type: 'fal',
        },
        {
          title: 'fish',
          searchTerms: [
            'fish',
            'fisch',
          ],
          type: 'fal',
        },
        {
          title: 'croissant',
          searchTerms: [
            'croissant',
          ],
          type: 'fal',
        },
        {
          title: 'taco',
          searchTerms: [
            'taco',
          ],
          type: 'fal',
        },
        {
          title: 'soup',
          searchTerms: [
            'suppe', 'soup',
          ],
          type: 'fal',
        },
        {
          title: 'sandwich',
          searchTerms: [
            'sandwich',
          ],
          type: 'fal',
        },
        {
          title: 'pizza',
          searchTerms: [
            'pizza',
          ],
          type: 'fal',
        },
        {
          title: 'beer',
          searchTerms: [
            'beer',
          ],
          type: 'fal',
        },
        {
          title: 'mug-hot',
          searchTerms: [
            'mug-hot',
          ],
          type: 'fal',
        },
        {
          title: 'glass-cheers',
          searchTerms: [
            'glass-cheers',
          ],
          type: 'fal',
        },
        {
          title: 'glass',
          searchTerms: [
            'glass',
          ],
          type: 'fal',
        },
        {
          title: 'utensils',
          searchTerms: [
            'utensils',
          ],
          type: 'fal',
        },
        {
          title: 'fish-cooked',
          searchTerms: [
            'fish-cooked',
          ],
          type: 'fal',
        },
        {
          title: 'turkey',
          searchTerms: [
            'turkey',
          ],
          type: 'fal',
        },
        {
          title: 'ice-cream',
          searchTerms: [
            'ice-cream',
          ],
          type: 'fal',
        },
        {
          title: 'flower-daffodil',
          searchTerms: [
            'flower-daffodil',
          ],
          type: 'fal',
        },
        {
          title: 'flower-tulip',
          searchTerms: [
            'flower-tulip',
          ],
          type: 'fal',
        },
        {
          title: 'dumbbell',
          searchTerms: [
            'dumbbell',
          ],
          type: 'fal',
        },
        {
          title: 'mouse',
          searchTerms: [
            'mouse',
          ],
          type: 'fal',
        },
        {
          title: 'plug',
          searchTerms: [
            'plug',
          ],
          type: 'fal',
        },
        {
          title: 'webcam',
          searchTerms: [
            'webcam',
          ],
          type: 'fal',
        },
        {
          title: 'tablet',
          searchTerms: [
            'tablet',
          ],
          type: 'fal',
        },
        {
          title: 'usb-drive',
          searchTerms: [
            'usb-drive',
          ],
          type: 'fal',
        },
        {
          title: 'watch-calculator',
          searchTerms: [
            'watch-calculator',
          ],
          type: 'fal',
        },
        {
          title: 'sd-card',
          searchTerms: [
            'sd-card',
          ],
          type: 'fal',
        },
        {
          title: 'mobile',
          searchTerms: [
            'mobile',
          ],
          type: 'fal',
        },
        {
          title: 'game-console-handheld',
          searchTerms: [
            'game-console-handheld',
          ],
          type: 'fal',
        },
        {
          title: 'gamepad-alt',
          searchTerms: [
            'gamepad-alt',
          ],
          type: 'fal',
        },
        {
          title: 'baseball',
          searchTerms: [
            'baseball',
          ],
          type: 'fal',
        },
        {
          title: 'shish-kebab',
          searchTerms: [
            'shish-kebab',
          ],
          type: 'fal',
        },
        {
          title: 'toilet-paper',
          searchTerms: [
            'toilet-paper',
          ],
          type: 'fal',
        },
        {
          title: 'shovel',
          searchTerms: [
            'shovel',
          ],
          type: 'fal',
        },
        {
          title: 'flashlight',
          searchTerms: [
            'flashlight',
          ],
          type: 'fal',
        },
        {
          title: 'binoculars',
          searchTerms: [
            'binoculars',
          ],
          type: 'fal',
        },
        {
          title: 'backpack',
          searchTerms: [
            'backpack',
          ],
          type: 'fal',
        },
        {
          title: 'axe',
          searchTerms: [
            'axe',
          ],
          type: 'fal',
        },
        {
          title: 'headphones',
          searchTerms: [
            'headphones',
          ],
          type: 'fal',
        },
        {
          title: 'mp3-player',
          searchTerms: [
            'mp3-player',
          ],
          type: 'fal',
        },
        {
          title: 'drone-alt',
          searchTerms: [
            'drone-alt',
          ],
          type: 'fal',
        },
        {
          title: 'camera-polaroid',
          searchTerms: [
            'camera-polaroid',
          ],
          type: 'fal',
        },

        {
          title: 'gift-card',
          searchTerms: [
            'gift-card',
            'card',
            'gift',
          ],
          type: 'fal',
        },
        {
          title: 'compact-disc',
          searchTerms: [
            'disc',
            'disk',
            'music',
          ],
          type: 'fal',
        },
      ],
      mealsIcons: [
        {
          title: 'leaf',
          searchTerms: [
            'leaf',
            'vegs',
            'vegan',
            'vegetables',
            'lunch'
          ],
          type: 'fal',
        },
        {
          title: 'wheat',
          searchTerms: [
            'wheat',
            'vegs',
            'vegan',
            'vegetables',
            'lunch'
          ],
          type: 'fal',
        },
        {
          title: 'turkey',
          searchTerms: [
            'turkey',
            'chicken',
          ],
          type: 'fal',
        },
        {
          title: 'egg',
          searchTerms: [
            'egg',
            'breakfast',
          ],
          type: 'fal',
        },
        {
          title: 'sheep',
          searchTerms: [
            'sheep',
          ],
          type: 'fal',
        },
        {
          title: 'cow',
          searchTerms: [
            'cow',
          ],
          type: 'fal',
        },
        {
          title: 'pig',
          searchTerms: [
            'pig',
          ],
          type: 'fal',
        },
        {
          title: 'fish',
          searchTerms: [
            'fish',
          ],
          type: 'fal',
        },
      ],
      hoverPanel: false,
      search: "",
      beforeSelect: "",
      selected: "",
    }
  },

  beforeMount() {
    this.selected = this.value ?? null;
  },

  computed: {
    iconsList(){
      if (this.type === 'food') {
        return this.foodIcons;
      } else if (this.type === 'meal') {
        return this.mealsIcons;
      }
      return this.statusIcons;
    },
    iconsFiltered: function () {
      let search = (this.search === this.selected) ? this.beforeSelect : this.search;
      if (!search) {
        return this.iconsList;
      }
      search = search.toLowerCase();

      return this.iconsList.filter(i =>
          i.title.indexOf(search) !== -1 || i.searchTerms.some(t => t.indexOf(search) !== -1)
      )
    }
  },

  watch: {
    selected: function (newValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue) {
      this.search = newValue;
      this.selected = newValue;
    }
  },

  methods: {
    blur() {
      this.timeout = setTimeout(() => {
        this.focusOn = false;
      }, 150);
    },
    focus() {
      this.focusOn = true;
    },
    select(icon) {
      clearTimeout(this.timeout)
      if (icon) {
        if (this.search !== this.selected) this.beforeSelect = this.search
        this.selected = icon.title;
      }
      this.$refs.picker.focus()
    },
    remove(){
      this.selected = null;
    }
  }
}
</script>

<style scoped>
/* In App.vue <style lang="scss" /> */
.custom-shadow {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.custom-shadow-sm {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* In FontawesomePicker.vue <style scoped lang="scss" /> */
.icon-preview-fade-enter-active, .icon-preview-fade-leave-active {
  transition: opacity 0.25s;
}

.icon-preview-fade-enter, .icon-preview-fade-leave-to {
  /* .fade-leave-active below version 2.1.8 */
  opacity: 0;
}

.preview-container {
  width: 300px;
  position: absolute;
}

.previewer {
  z-index: 10;
  position: absolute;
  width: 100%;
  min-height: 50px;
  max-height: 500px;
  overflow: auto;
  background: white;
  top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.icon-preview {
  width: 10%;
  padding-top: 10%;
  position: relative;
}

@media (max-width: 800px) {
  .icon-preview {
    width: 15%;
    padding-top: 15%;
  }
}

.icon-preview .icon-wrapper {
  position: absolute;
  height: 80%;
  width: 80%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease-in-out all 0.25s;
}

.icon-preview .icon-wrapper:hover, .icon-preview .icon-wrapper.selected {
  background: #42b883;
  color: #fbfbfb;
}

.icon-preview .icon-wrapper i {
  font-size: 4vw;
}

</style>
